<template>
  <div class="rootContainer">
    <header v-bind:class="$route.meta.headerClass">
      <router-link to="/" tag="div" class="logo"></router-link>
      <p>Geographic Information System, Infinite Technology to Reality</p>
      <common-navigator />
      <common-location />
    </header>
    <main>
      <router-view />
      <i class="fas fa-chevron-up upicon"><p>Top</p></i>
    </main>
    <common-footer />
  </div>
</template>

<script charset="UTF-8">
  export default {
    mounted(){
      window.scrollTo(0,0);
    }
  }
</script>
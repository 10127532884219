<template>
	<component :is="layout">
		<router-view />
	</component>
</template>

<script>
const defaultLayout = "page-layout";
export default {
  computed: {
    layout() {
      return (this.$route.meta.layout || defaultLayout);
    }
  }
}
</script>

import Vue from 'vue'
import VueRouter from 'vue-router'
import jwt from 'jsonwebtoken'
import { publicKey } from './../sso/properties.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      layout: "home-layout"
    },
    component: () => import ('../views/home.vue')
  },
  {
    path: '/sub1_a',
    name: ['ABOUT US', '회사소개'],
    meta: {
      layout: "page-layout",
      headerClass: "type1"
    },
    component: () => import ('../views/sub1_a.vue'),
    // beforeEnter: (to, from, next) => {
    //   let token = `eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyTm0iOiLsi5zsiqTthZzqtIDrpqzsnpAiLCJ1c2VyX25hbWUiOiJhZG1pbiIsInNjb3BlIjpbImFsbCJdLCJleHAiOjE2NjAxODA1MDAsInVzZXJJZCI6ImFkbWluIiwiYXV0aG9yaXRpZXMiOlsiUk9MRV9TWVNURU0iLCJST0xFX0FETUlOIiwiUk9MRV9VU0VSIl0sImp0aSI6IjkxMmVlNDAxLWE4ZWEtNGJkOC05NzNmLTM5ZGJmOGU5MGRkMiIsImNsaWVudF9pZCI6InBvcnRhbCJ9.Wk17YC-Hx49POsQet9AU_0NpTr-8QgFexaT47yj6nPZvykpKgLHn0tPfX4AG6WNTsF8EyTW-AwrwhYFBU-oGCie7JQnwUg9TLb5qIMZIfeBws3geB2tv3vc4ki5toa2reitfyHD9nBIueIfjAaXuO90z8Oas7et_77RyROkACSzKXZyOgeLJW9ytVSnzpVJPA3Cvrv_ZmJjqMgxdBNPRpoT9QMArgiu-ybOSEI8li_tWjjFmf_TUYjJpuq9RFpyZVw8Ka5jfbsvxU0YyBiKCHnx9IEbDeiHgMrZdIyDchg3CKB7KtDQCsEzZCI1m0XH10H_GRB_rdxqxWTPc8lZNaA`;
    //   jwt.verify(token, publicKey, (error, decoded)=>{
    //     if(error){
    //       console.log(error);
    //     }else{
    //       next();
    //     }
    //   });
    // }
  },
  {
    path: '/sub1_b',
    name: ['ABOUT US', '연혁'],
    meta: {
      layout: "page-layout",
      headerClass: "type1"
    },
    component: () => import ('../views/sub1_b.vue')
  },
  {
    path: '/sub1_c',
    name: ['ABOUT US', '구성원'],
    meta: {
      layout: "page-layout",
      headerClass: "type1"
    },
    component: () => import ('../views/sub1_c.vue')
  },
  {
    path: '/sub1_d',
    name: ['ABOUT US', '오시는 길'],
    meta: {
      layout: "page-layout",
      headerClass: "type1"
    },
    component: () => import ('../views/sub1_d.vue')
  },
  {
    path: '/sub2_a',
    name: ['SERVICE', 'platform'],
    meta: {
      layout: "page-layout",
      headerClass: "type2"
    },
    component: () => import ('../views/sub2_a.vue')
  },
  {
    path: '/sub2_b',
    name: ['SERVICE', 'dashboard'],
    meta: {
      layout: "page-layout",
      headerClass: "type2"
    },
    component: () => import ('../views/sub2_b.vue')
  },
  {
    path: '/sub2_c',
    name: ['SERVICE', 'gis'],
    meta: {
      layout: "page-layout",
      headerClass: "type2"
    },
    component: () => import ('../views/sub2_c.vue')
  },
  {
    path: '/sub2_e',
    name: ['SERVICE', 'open gis'],
    meta: {
      layout: "page-layout",
      headerClass: "type2"
    },
    component: () => import ('../views/sub2_e.vue')
  },
  {
    path: '/sub2_d',
    name: ['SERVICE', 'mobile'],
    meta: {
      layout: "page-layout",
      headerClass: "type2"
    },
    component: () => import ('../views/sub2_d.vue')
  },
  {
    path: '/sub3',
    name: ['NOTICE', '공지사항'],
    meta: {
      layout: "page-layout",
      headerClass: "type3"
    },
    component: () => import ('../views/sub3.vue')
  },
  {
    path: '/sub3_detail',
    name: ['NOTICE', '공지사항'],
    meta: {
      layout: "page-layout",
      headerClass: "type3"
    },
    component: () => import ('../views/sub3_detail.vue')
  },
  {
    path: '/sub4',
    name: ['CONTACT', '문의사항'],
    meta: {
      layout: "page-layout",
      headerClass: "type4"
    },
    component: () => import ('../views/sub4.vue')
  },
  {
    path: '*',
    name: 'notFound',
    meta: {
      layout: "error-layout"
    },
    component: () => import ('../views/error/404.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
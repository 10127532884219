import Vue from 'vue'
import App from './App.vue'
import router from './router'

import HomeLayout from './views/layout/homeLayout.vue'
import PageLayout from './views/layout/pageLayout.vue'
import ErrorLayout from './views/layout/errorLayout.vue'

import Navigator from './views/component/nav.vue';
import Footer from './views/component/footer.vue';
import Location from './views/component/location.vue';

Vue.component("home-layout", HomeLayout);
Vue.component("page-layout", PageLayout);
Vue.component("error-layout", ErrorLayout);

Vue.component("common-navigator", Navigator);
Vue.component("common-footer", Footer);
Vue.component("common-location", Location);

Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
